import {getPageReportId} from "./reportId.js";
import {closeMenu, megaMenuId, megaMenuInputId} from "./menu.js";
import "./popinActionsDiverses.js";

const _dialog: HTMLDialogElement | null = document.querySelector<HTMLDialogElement>('dialog#dialog');
if (_dialog === null) {
	throw new Error('NO_DIALOG_FOUND');
}

export const dialog = _dialog;

export type OpenDialogEvent = CustomEvent<{
	dialog: HTMLDialogElement,
	content: HTMLElement,
	content_id: string,
}>



/**
 * Trigger click event, adding x and y to make sure it triggers a truly valid event (popin close event)
 * @param $element
 */
function triggerClick($element: HTMLElement) {
	const boundingRect = $element.getBoundingClientRect();
	$element.dispatchEvent(new MouseEvent("click", {
		bubbles: true,
		cancelable: true,
		clientX: boundingRect.x,
		clientY: boundingRect.y
	}))
}



interface IPopInConfig {
	$id: RegExp,
	target: string
}
interface IPopInConfigAjax {
	$id: RegExp,
	url: ((reportId: string, regexResult: RegExpExecArray) => string)
	sortableNeeded?: ((regexResult: RegExpExecArray) => boolean)
}
const popInRouting: (IPopInConfig | IPopInConfigAjax)[] = [
	{
		$id: /^_(?<name>requestmap)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/basic?${new URLSearchParams({
				name: name ?? '',
			})}`;
		}
	},
	{
		$id: /^_(?<name>waterfall)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/requestDataAndMain?${new URLSearchParams({
				name: name ?? '',
			})}`;
		}
	},
	{
		$id: /^_(?<name>filmstrip)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/activityDataAndMain?${new URLSearchParams({
				name: name ?? '',
			})}`;
		}
	},
	{
		$id: /^_(?<name>dom|js|seo)_(?<tab>validation_w3c|console|pagemap|social|schema|head_order)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab
				;
			return `/result/${reportId}/popin/mainData?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return ['validation_w3c', 'console', 'domaines', 'head_order'].includes(result.groups?.tab ?? '');
		}
	},
	{
		$id: /^_(?<name>seo)_(?<tab>popularity)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab
				;
			return `/result/${reportId}/popin/popularityData?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
	},
	{
		$id: /^_(?<name>js)_(?<tab>acces_dom)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab
				;
			return `/result/${reportId}/popin/activityDataAndMain?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return ['acces_dom'].includes(result.groups?.tab ?? '');
		}
	},
	{
		$id: /^_(?<name>video|metriques|score_lighthouse)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/mainData?${new URLSearchParams({
				name: name ?? '',
			})}`;
		}
	},
	{
		$id: /^_(?<name>requetes|poids_et_requetes)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/requestData?${new URLSearchParams({
				name: name ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return result.groups?.name === 'requetes';
		}
	},
	{
		$id: /^_(?<name>traitements)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name;
			return `/result/${reportId}/popin/mainData?${new URLSearchParams({
				name: name ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return result.groups?.name === 'traitements';
		}
	},
	{
		$id: /^_(?<name>js|1p3p)_(?<tab>.*?)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab;
			return `/result/${reportId}/popin/requestDataAndMain?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return ['table', 'fichiers'].includes(result.groups?.tab ?? '') || (result.groups?.name === '1p3p' && ['entites', 'domaines'].includes(result.groups?.tab));
		}
	},
	{
		$id: /^_(?<name>css|poids|requetes|dom)_(?<tab>.*?)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab
				;
			return `/result/${reportId}/popin/requestData?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return ['table', 'images'].includes(result.groups?.tab ?? '');
		}
	},
	{
		$id: /^_(?<name>eco)_(?<tab>domaines|synthese)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab;
			return `/result/${reportId}/popin/mainData?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return result.groups?.tab === 'domaines';
		}
	},
	{
		$id: /^_(?<name>seo)_(?<tab>.*?)$/i,
		url(reportId: string, regexResult: RegExpExecArray) {
			const name = regexResult.groups?.name,
				tab = regexResult.groups?.tab;
			return `/result/${reportId}/popin/seoData?${new URLSearchParams({
				name: name ?? '',
				tab: tab ?? '',
			})}`;
		},
		sortableNeeded(result) {
			return ['keywords', 'links_out', 'pagemap'].includes(result.groups?.tab ?? '');
		}
	}
];

type ExtendedWindow = Window & typeof globalThis & {
	_wsq?: Array<any>
}
const w : ExtendedWindow = window;
w._wsq = w._wsq || [];

async function loadPopin(reportId: string, content_id: string) {
	let ajaxUrl: string | null = null,
		regexResult: RegExpExecArray | null = null,
		loadSortable = false;


	for (let config of popInRouting) {
		regexResult = config.$id.exec(content_id);
		if (!regexResult) continue;

		if ('url' in config && config.url) {
			ajaxUrl = config.url(reportId, regexResult);
			if (ajaxUrl) {
				if (config.sortableNeeded) {
					loadSortable = config.sortableNeeded(regexResult);
				}
				break;
			}
		} else if ('target' in config && config.target) {
			const target = document.querySelector<HTMLElement>(config.target);
			if (!target) {
				throw new Error('TARGET_NOT_FOUND_FOR_' + config.$id);
			}
			return target;
		}
	}

	if (!ajaxUrl) {
		throw new Error('UNKNOWN_ID');
	}

	if(w._wsq !== undefined) {
		w._wsq.push(function () {
			// @ts-ignore
			_setEvent(`0;Ouverture;Popin;${content_id}`);
			// @ts-ignore
			_wysistat();
		});
	}

	if (loadSortable) {
		// @ts-ignore
		await import('sortable-tablesort')
			.catch(console.error)
			;
	}

	const popinAjax = document.querySelector<HTMLElement>('#pop_ajax');
	if (!popinAjax) {
		throw new Error('MISSING_AJAX_CONTAINER');
	}
	const ajaxResponse = await fetch(ajaxUrl);
	document.body.classList.toggle('dialog_mini', !ajaxResponse.ok);
	popinAjax.innerHTML = await ajaxResponse.text();

	setTimeout(() => {
		for (let $element of popinAjax.querySelectorAll<HTMLElement>('[data-sort-onload]')) {
			triggerClick($element);
			triggerClick($element);
		}
	})

	return popinAjax;
}

export const popInHashPrefix = /^_?pop_/i;
export async function openDialog(content_id: string) {
	if (dialog.dataset.openedDialog === content_id) {
		console.debug('Popin already opened');
		return;
	}

	let content: HTMLElement | null = null;
	if (!content_id.startsWith('_')) {
		content = dialog.querySelector<HTMLElement>(`#${content_id}`);
		if (content) {
			document.body.classList.toggle('dialog_mini', content.classList.contains('mini'));
		}
	}
	if (!content) {
		const reportId = getPageReportId();
		if (!reportId) {
			throw new Error('REPORT_ID_MISSING');
		}
		document.body.classList.add('loading');
		content = await loadPopin(reportId, content_id);
		document.body.classList.remove('loading');
	}

	closeMenu();

	const element = dialog.querySelector('.open');
	if (element) element.classList.remove('open');

	content.classList.add('open');
	document.documentElement.classList.add('scroll_disabled');
	dialog.dataset.openedDialog = content_id;
	location.hash = `#${content_id.startsWith('_') ? '_' : ''}pop${content_id.startsWith('_') ? '' : '_'}${content_id}`;

	const event: OpenDialogEvent = new CustomEvent('a4i_openDialog', {
		detail: {
			content_id,
			dialog,
			content
		}
	});
	document.dispatchEvent(event);

	if (!dialog.open) {
		document.addEventListener('click', onPopinClose);
		setTimeout(() => { dialog.show() });
	}
}

document.addEventListener('wheel', (evt) => {
	const scrollContainer = (<HTMLElement>evt.target).closest('.scroll');
	if (!scrollContainer) return;

	evt.preventDefault();
	scrollContainer.scrollLeft += evt.deltaY;
})

document.addEventListener('keyup', function (evt: KeyboardEvent) {
	const opened = dialog.querySelector('.open');
	if (!opened) {
		return;
	}

	if (["Escape", "Esc"].includes(evt.key)) {
		onCloseDialog();
	}
});

export function closeDialog() {
	dialog.close();
	dialog.classList.remove('close');
	document.documentElement.classList.remove('scroll_disabled');
	document.body.classList.remove('dialog_mini');

	const opened = dialog.querySelector('.open');
	if (opened?.id === 'pop_ajax') {
		for (let node of [...opened.children]) {
			node.remove();
		}
	}
	opened?.classList.remove('open');

	dialog.removeEventListener('animationend', closeDialog);
	dialog.dataset.openedDialog = '';
	document.body.dispatchEvent(new Event("popinclose"));

	if (popInHashPrefix.test(location.hash.replace(/^#/, ''))) {
		const url = new URL(location.href);
		url.hash = '';
		history.pushState({}, '', url);
	}
}

function onPopinClose(e: MouseEvent) {
	if (!dialog.open) {
		return;
	}

	if (!(<HTMLElement | null>e.target)?.closest(`#${dialog.id}, label[for="${megaMenuInputId}"], #${megaMenuInputId}, #${megaMenuId}`)) {
		onCloseDialog();
	}
}

function onCloseDialog(e?: Event) {
	if (e) {
		const element = (<HTMLElement>e.target).closest('#close-dialog');
		if (!element) return;
	}

	document.removeEventListener('click', onPopinClose);
	dialog.addEventListener('animationend', closeDialog);
	dialog.classList.add('close');
}
document.addEventListener('click', onCloseDialog);
