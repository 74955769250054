import {dialog, OpenDialogEvent} from "./dialog_popin.js";

document.addEventListener("a4i_openDialog", function fn(e: OpenDialogEvent) {
	const content = e.detail.content;
	switch (e.detail.content_id) {
		case "_js_acces_dom":
			if (content.dataset.buttonInit === undefined) {
				content.dataset.buttonInit = '1';
				content.addEventListener("change", accessDom_onFilterChange);
			}
			break;
		case "_filmstrip":
			if (content.dataset.buttonInit === undefined) {
				content.dataset.buttonInit = '1';
				content.addEventListener("change", filmstrip_onConciseChange);
				content.addEventListener("click", filmstrip_onPosition);
			}
			break;
		case "_video":
			break;
	}
}, false);

function accessDom_onFilterChange(e: Event) {
	const filterInput = (<HTMLElement>e.target).closest('input.filter_input[type="checkbox"]');
	if (!filterInput || !filterInput.parentElement) return;

	const $count = filterInput.parentElement.querySelector('.tr_count');
	if (!$count) return;

	const tableRows = [...filterInput.parentElement.querySelectorAll<HTMLElement>('table tbody tr:not(.metric)')]
		.filter(node => node.offsetParent !== null)
	;
	$count.textContent = tableRows.length.toString();
}

function filmstrip_onConciseChange(e: Event) {
	const filterInput = (<HTMLElement>e.target).closest('input#only_keyframe[type="checkbox"]');
	if (!filterInput) return;

	filterInput.closest('.filmstrip')?.classList.toggle("activity");
}

/**
 * lazy-loading timeline container
 */
let $timelineContainer: HTMLElement|null = null;
function filmstrip_onPosition(e: Event) {
	const position = (<HTMLElement>e.target).closest<HTMLElement>('[data-filmstrip-position]');
	if (!position || !position.dataset.filmstripPosition) return;

	const $target = dialog.querySelector<HTMLElement>(`:scope #${position.dataset.filmstripPosition}`);
	if (!$target) {
		throw new Error('[FILMSTRIP-POSITION] Target position does not exist.');
	}

	if ($timelineContainer === null || !$timelineContainer.offsetParent) {
		$timelineContainer = $target.closest<HTMLElement>('.timeline_container');
		if (!$timelineContainer) {
			throw new Error('TIMELINE_CONTAINER_NOT_FOUND');
		}
	}

	$target.focus({
		preventScroll: true,
	});
	$timelineContainer.scrollTo({
		behavior: 'smooth',
		left: $target.offsetLeft + $target.clientWidth / 2 - ($timelineContainer.offsetWidth / 2)
	});
}
