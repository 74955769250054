let pageReportId: string | null = null;
let progression: number | null = null;
export function setPageReportId(newReportId: string) {
	pageReportId = newReportId;
	console.debug('New reportId', pageReportId, progression);
}
export function getPageReportId(): string | null {
	return pageReportId;
}

export function setProgression(newProgression: number): number {
	if (newProgression && newProgression < 1) newProgression = 1;
	progression = newProgression;
	document.dispatchEvent(new Event('a4i_report_progress'));
	return progression;
}
export function getProgression(): number | null {
	return progression;
}
