import { io, Socket } from "socket.io-client";
import { analysisStatusUpdateLinks, ClientToServerEvents, ServerToClientEvents } from "../../src/types/socketIo.js";
import { statusResultType } from "../../src/types/results.js";
import {getPageReportId, getProgression, setProgression} from "./reportId.js";

function sleep(ms?:number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, ms));
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io({
	reconnectionDelay: 2000,
	reconnectionDelayMax: 10000,
	randomizationFactor: 1,
	/*extraHeaders: {
		token: "..."
	}*/
});

let statusNames: Awaited<ReturnType<typeof getStatusNames>> | null | undefined = null;
socket.on('connect', async () => {
	statusNames = await getStatusNames()
});

socket.on('analysisStatusUpdate', function (reportId: string, status: statusResultType, links: analysisStatusUpdateLinks, isErrored:boolean) {
	if (getPageReportId() !== reportId) return;

	updateStatuses(status, links, isErrored);
});

const milkshake_progress = document.querySelector<HTMLElement>('.milkshake.anim');
let nbr_step_apply = 0;

function updateStatuses(status: statusResultType, links: analysisStatusUpdateLinks, isErrored:boolean) {
	const ulElement = document.querySelector('#status-list');
	if (!ulElement) {
		throw new Error("NO_STATUS_LIST");
	}

	let nbStatus: number | false = false,
		nbFinished: number = 0
	;
	if (statusNames) {
		nbStatus = Object.keys(statusNames).length - (status.cmp?.status !== undefined ? 1 : 0)
	}
	if (milkshake_progress && isErrored) {
		milkshake_progress.classList.add('error');
	}

	const wptLink = document.querySelector<HTMLElement>('#wpt-linkUrl');
	if (wptLink && links.wpt) {
		const newLink = document.createElement('a');
		newLink.href = links.wpt.link;
		newLink.target = 'wpt_' + links.wpt.id;
		newLink.textContent = wptLink.textContent;
		wptLink.replaceWith(newLink);
	}

	// console.debug(pageReportId, status, links);
	for (const [id, _status] of Object.entries(status)) {
		const value = _status?.status;

		let statusElement = ulElement.querySelector<HTMLElement>(`#status_${id} .status`),
			liElement = statusElement?.parentElement
			;
		if (!statusElement || !liElement) {
			throw new Error(`MISSING_ELEMENT_STATUS "${id}"`);
		}

		liElement.classList.toggle('status_done', value === true);
		liElement.classList.toggle('status_in_progress', value === false);
		liElement.classList.toggle('status_not_started', value === undefined);
		liElement.classList.toggle('status_errored', typeof value === "string");

		if (value === true || typeof value === "string") {
			nbFinished++;
		}
		if (value !== undefined) {
			statusElement.innerHTML = value === true ? '✔' : '';
			statusElement.classList.toggle('loader', value === false);
			statusElement.classList.toggle('ico', typeof value === "string");
			if (typeof value === "string") {
				statusElement.setAttribute('data-ico', 'close');
			} else {
				statusElement.removeAttribute('data-ico');
			}
		}
	}

	if (nbStatus !== false) {
		setProgression(Math.floor(10 * nbFinished / nbStatus));
	}
}
socket.on("reportComplete", async (reportId: string) => {
	if (getPageReportId() !== reportId) return;

	await sleep(350);
	if (milkshake_progress) milkshake_progress.classList.add('step10');

	await sleep(1500);
	if (milkshake_progress) milkshake_progress.classList.add('step11');

	await sleep(2000);
	if (milkshake_progress) milkshake_progress.classList.add('end');

	await sleep(2250);
	// Ne permets pas de voir la transition API...
	location.reload();
});

export async function ping() {
	return new Promise(resolve => {
		socket.emit('ping', resolve);
	})
}

export async function getStatusNames() {
	return socket.emitWithAck('getStatusNames');
}

export async function addClassForAnimProgress() {
	const progress = getProgression();
	if (!milkshake_progress || progress === null) return;

	nbr_step_apply++;
	milkshake_progress.classList.add(`step${nbr_step_apply}`);
	if (nbr_step_apply < progress) {
		await sleep(233);
		addClassForAnimProgress()
			.catch(console.error);
	}
}

document.addEventListener('a4i_report_progress', () => {
	const progress = getProgression();
	console.debug(progress);
	if (milkshake_progress && progress && nbr_step_apply < progress) {
		addClassForAnimProgress()
			.catch(console.error);
	}
});
